window.esign = window.esign || {};

esign.cacheSelectors = function () {
  esign.cache = {
    // general
    $html: $("html"),

    // navigation
    $nav: $(".main-nav__wrap"),
  };
};

esign.init = function () {
  Response.create({
    prop: "width",
    prefix: "min-width- r src",
    breakpoints: [752, 0],
    lazy: true,
  });

  esign.gaDevelopment();
  esign.cacheSelectors();

  esign.navigation();
  esign.responsiveVideos();
  esign.formAjax();
  esign.initFloatingLabels();
  esign.initSlider();
  esign.initRegisterForm(); // call before initTabs
  esign.initTabs();
  esign.initControlList();
  esign.initModals();
  esign.initAccordions();
  esign.initSelect2();
  esign.scrollOnPageLinks();
  esign.initAanbodTabsOnPageLoad();
  esign.initNewsletterForm();
  esign.initForms();
  esign.initListFilterTags();
  esign.testDiscountCode();

  if ($("#map").length) {
    esign.initMap();
  }

  if (document.querySelector(".json-fetcher")) {
    esign.jsonFetcher(document.querySelector(".json-fetcher"));
  }
};

esign.jsonFetcher = function (element) {
  var template = element.querySelector(".json-fetcher__template");
  var keys = element.dataset.keys.split(";");
  var api = element.dataset.api;

  $.get(api, function (data) {
    data.data.forEach(function (item) {
      var templateClone = template.cloneNode(true);
      keys.forEach(function (key) {
        try {
          var keyParts = key.split(".");
          var index = 0;
          var data = item;
          while (index + 1 <= keyParts.length) {
            data = data[keyParts[index]];
            index += 1;
          }
          templateClone.innerHTML = templateClone.innerHTML.replace(
            "__" + key + "__",
            data
          );
        } catch (error) {
          console.error(error);
        }
      });
      templateClone.innerHTML = templateClone.innerHTML.replace(
        "data-src",
        "src"
      );
      element.appendChild(templateClone.children[0]);
    });
    template.parentNode.removeChild(template);
  });
};

esign.navigation = function () {
  $(".main-nav__trigger").click(function (e) {
    e.preventDefault();
    $(this).next(".main-nav__wrap").slideToggle("fast");
  });

  Response.crossover("width", function () {
    if (Response.band(752)) {
      esign.cache.$nav.css("display", "block");
    } else {
      esign.cache.$nav.css("display", "none");
    }
  });
};

esign.responsiveVideos = function () {
  $(
    'iframe[src*="youtube.com/embed"], iframe[src*="youtube-nocookie.com/embed"], iframe[src*="player.vimeo"]'
  ).wrap('<div class="video-container"></div>');
};

// Ajax newsletter subscribe
esign.formAjax = function () {
  $(".form-ajax").submit(function (e) {
    var $form = $(this);
    $form.find('button,input[type="submit"]').attr("disabled", "disabled");

    $.post($form.attr("action"), $form.serializeArray(), function (data) {
      if (data.errors === false) {
        $form.html(data.result);
      } else {
        $form.find(".result").html(data.result);
        $form.find('button, input[type="submit"]').removeAttr("disabled");
      }
    });

    e.preventDefault();
    return false;
  });
};

// Log ga calls in development
esign.gaDevelopment = function () {
  if (typeof ga === typeof undefined) {
    window.ga = function () {
      var argumentsArray = [];
      for (var key in arguments) {
        if (arguments.hasOwnProperty(key)) {
          argumentsArray.push(arguments[key]);
        }
      }

      var msg =
        "[GA DEV] Call with arguments [" + argumentsArray.join(",") + "]";
      console.log("%c" + msg, "background: #ff9800; color: #fff;");
    };
  }
};

esign.initFloatingLabels = function (query) {
  if (!query) {
    query = "input, textarea";
  }
  var $inputs = $(query);
  $inputs.change(function () {
    if ($(this).val() && $(this).val() !== "") {
      $(this).addClass("float-label");
    } else {
      $(this).removeClass("float-label");
    }
  });
};

esign.initSlider = function () {
  $(".slider").each(function () {
    var $actions = $($(this).data("actions"));
    var $actionLeft = $actions.find(".slider__action-left");
    var $actionRight = $actions.find(".slider__action-right");
    var $dotsWrapper = $actions.find(".slider__dots");
    var $dots;

    $(this).on(
      "init",
      function (event, slick) {
        $dots = $actions.find(".slick-dots li");
        if ($dots.length === 0) {
          $actions.hide();
        }
        $(this).addClass("initialized");
      }.bind(this)
    );

    var slick = $(this).slick({
      dots: true,
      prevArrow: false,
      nextArrow: false,
      appendDots: $dotsWrapper,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 1000,
    });

    slick.on(
      "beforeChange",
      function (event, slick, currentSlide, nextSlide) {}.bind(this)
    );

    slick.on("afterChange", function (slick, currentSlide) {}.bind(this));

    $actionLeft.click(
      function () {
        slick.slick("slickPrev");
      }.bind(this)
    );

    $actionRight.click(
      function () {
        slick.slick("slickNext");
      }.bind(this)
    );
  });
};

esign.initTabs = function () {
  $(".tab-list").each(function () {
    var $this = $(this);
    var $tab = $this.find(".tab-list__tab.active");
    var $link = $tab.find("a");
    var $panel = $($link.attr("href"));

    var tablistId = $this.attr("id");
    var $extraTabControls;

    if (tablistId) {
      $extraTabControls = $(
        '.tab-control[data-tab-control-for="#' + tablistId + '"]'
      );
    }

    $this
      .find(".tab-control")
      .add($extraTabControls)
      .click(function (e) {
        e.preventDefault();
        var $link = $(this);
        var id = this.hash;

        if (id !== window.location.hash) {
          window.location.hash = id;
        }

        if (id && !$link.is(".active")) {
          $panel.removeClass("active");
          $tab.removeClass("active");

          $panel = $(id).addClass("active");
          if ($link.data("tab-control-for")) {
            $tab = $($link.data("tab-control-for"))
              .find('[href="' + $link.attr("href") + '"]')
              .closest(".tab-list__tab")
              .addClass("active");
          } else {
            $tab = $(this).closest(".tab-list__tab").addClass("active");
          }
        }

        // custom
        var $aanbodBanner = $(".visual--banner-aanbod");
        if ($aanbodBanner.length) {
          $aanbodBanner.removeClass("yellow red green blue");
          var classes = $tab.attr("class");
          if (classes.indexOf(" yellow ") >= 0) {
            $aanbodBanner.addClass("yellow");
          } else if (classes.indexOf(" red ") >= 0) {
            $aanbodBanner.addClass("red");
          } else if (classes.indexOf(" blue ") >= 0) {
            $aanbodBanner.addClass("blue");
          } else if (classes.indexOf(" green ") >= 0) {
            $aanbodBanner.addClass("green");
          }
        }
      });
  });
};

esign.initControlList = function (query) {
  if (!query) {
    query = "dd[data-listen-to]";
  }
  $(query).each(function () {
    var $this = $(this);
    var $listeningTo = $($this.data("listen-to"));

    $listeningTo.change(function () {
      if ($(this).attr("type") === "checkbox") {
        if ($(this).is(":checked")) {
          $this.html("Ja");
        } else {
          $this.html("Nee");
        }
      } else {
        $this.html($(this).val());
      }
    });

    $listeningTo.trigger("change");
  });
};

esign.initModals = function () {
  $(".modal-trigger").click(function (e) {
    e.preventDefault();
    var modalId = $(this).data("modal");
    $(modalId).addClass("show");
    $("body, html").css({ overflow: "hidden" });
  });

  $(".modal__close, .modal-close-trigger").click(function (e) {
    $(this).closest(".modal").removeClass("show");
    $("body, html").css({ overflow: "auto" });
  });

  $(".modal__content").click(function (e) {
    e.stopPropagation();
  });

  $(".modal").click(function (e) {
    $(this).removeClass("show");
    $("body, html").css({ overflow: "auto" });
  });
};

esign.initAccordions = function () {
  $(".accordion-trigger").click(function (e) {
    e.preventDefault();

    var $this = $(this),
      $target = null;

    // get target
    if ($this.is("a")) {
      $target = $($this.attr("href"));
    } else if ($this.is("label")) {
      $target = $("#" + $this.attr("for")).closest(".accordion-content");
    } else if ($this.is("div")) {
      $target = $($this.data("href"));
    }

    // toggle target
    if ($target !== null) {
      $this.toggleClass("active");
      $target.toggle("fast");
    }
  });
};

esign.initSelect2 = function () {
  $("select").each(function () {
    var placeholderText = $(this).data("placeholder");
    $(this).select2({
      minimumResultsForSearch: 10,
      placeholder: placeholderText,
      allowClear: true,
    });
  });
};

if ($("#map").length) {
  esign.addContactGoogleMaps = function (
    container,
    latitude,
    longitude,
    hasBounds,
    zoom
  ) {
    if (hasBounds == true) {
      useBounds = true;
    }

    if (!zoom) {
      zoom = 8;
    }
    (disable = true),
      (scroll = false),
      (styledMap = new google.maps.StyledMapType([
        {
          featureType: "all",
          elementType: "labels.text.fill",
          stylers: [
            { saturation: 36 },
            { color: "#333333" },
            { lightness: 40 },
          ],
        },
        {
          featureType: "all",
          elementType: "labels.text.stroke",
          stylers: [
            { visibility: "on" },
            { color: "#ffffff" },
            { lightness: 16 },
          ],
        },
        {
          featureType: "all",
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "administrative",
          elementType: "geometry.fill",
          stylers: [{ color: "#fefefe" }, { lightness: 20 }],
        },
        {
          featureType: "administrative",
          elementType: "geometry.stroke",
          stylers: [{ color: "#fefefe" }, { lightness: 17 }, { weight: 1.2 }],
        },
        {
          featureType: "administrative",
          elementType: "labels.text.fill",
          stylers: [{ color: "#ff5619" }],
        },
        {
          featureType: "administrative.country",
          elementType: "all",
          stylers: [{ color: "#a6a6a6" }],
        },
        {
          featureType: "administrative.country",
          elementType: "labels",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "landscape",
          elementType: "geometry",
          stylers: [{ color: "#f8f8f8" }, { lightness: 20 }],
        },
        {
          featureType: "poi",
          elementType: "geometry",
          stylers: [{ color: "#f5f5f5" }, { lightness: 21 }],
        },
        {
          featureType: "poi.park",
          elementType: "all",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [{ color: "#dedede" }, { lightness: 21 }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.fill",
          stylers: [{ color: "#ffffff" }, { lightness: 17 }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [{ color: "#ffffff" }, { lightness: 29 }, { weight: 0.2 }],
        },
        {
          featureType: "road.arterial",
          elementType: "geometry",
          stylers: [{ color: "#ffffff" }, { lightness: 18 }],
        },
        {
          featureType: "road.local",
          elementType: "geometry",
          stylers: [{ color: "#ffffff" }, { lightness: 16 }],
        },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [{ color: "#f2f2f2" }, { lightness: 19 }],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [{ color: "#bfdde8" }, { lightness: 17 }],
        },
      ])),
      (mapCenter = new google.maps.LatLng(latitude, longitude)),
      (mapOptions = {
        zoom: zoom,
        panControl: true,
        zoomControl: disable,
        scaleControl: true,
        mapTypeControl: false,
        streetViewControl: false,
        overviewMapControl: false,
        minZoom: 9,
        scrollwheel: scroll,
        center: mapCenter,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      }),
      (map = new google.maps.Map(
        document.getElementById(container),
        mapOptions
      ));

    map.mapTypes.set("map_style", styledMap);
    map.setMapTypeId("map_style");

    google.maps.event.addListenerOnce(map, "bounds_changed", function (event) {
      if (this.getZoom() > 13) {
        this.setZoom(13);
      }
    });

    google.maps.event.addDomListener(window, "resize", function () {
      //map.setCenter(mapCenter);
      if (useBounds) {
        map.fitBounds(bounds);
      }
    });

    return map;
  };

  var marker_url = baseUrl + "/assets/images/map-marker.png";
  var markers = {};
  var useBounds, bounds;

  if (typeof google === "object" && typeof google.maps === "object") {
    useBounds = false;
    bounds = new google.maps.LatLngBounds();
  }

  esign.addContactGoogleMapsMarker = function (
    map,
    latitude,
    longitude,
    title,
    date,
    city,
    image,
    url
  ) {
    var myLatlng = new google.maps.LatLng(latitude, longitude),
      markerIcon = {
        url: marker_url,
        size: new google.maps.Size(12, 12),
        scaledSize: new google.maps.Size(12, 12),
        anchor: new google.maps.Point(6, 6),
      },
      marker = new google.maps.Marker({
        position: myLatlng,
        map: map,
        icon: markerIcon,
      });

    var contentString;
    contentString =
      "<div class='iw-content'><div class='visual'><div class='visual__img' style='background-image: url(\"" +
      image +
      "\")'></div></div><div class='iw-content__inner'><p>" +
      date +
      "<br/>" +
      city +
      "</p><span class='iw-content__title'>" +
      title +
      '</span><div><a href="' +
      url +
      '">Meer info</a></div></div></div>';

    var infowindow = new google.maps.InfoWindow({
      content: contentString,
      maxWidth: 233,
    });

    google.maps.event.addListener(marker, "click", function () {
      infowindow.open(map, this);
    });

    google.maps.event.addListener(infowindow, "domready", function () {
      var $l = $(".iw-content");
      $l.parent().parent().parent().parent().addClass("iw-container");
    });

    if (useBounds == true) {
      bounds.extend(myLatlng);
      map.fitBounds(bounds);
    }

    return marker;
  };

  esign.initMap = function () {
    if ($("#map").length) {
      if ($("body").hasClass("calendar")) {
        var map = esign.addContactGoogleMaps("map", 51.122689, 3.800265, true);
        var $marker = $(".marker");
        $marker.each(function () {
          esign.addContactGoogleMapsMarker(
            map,
            $(this).data("lat"),
            $(this).data("long"),
            $(this).data("title"),
            $(this).data("date"),
            $(this).data("city"),
            $(this).data("image"),
            $(this).data("url")
          );
        });
      }
    }
  };
}

esign.scrollOnPageLinks = function () {
  $("a[href*=\\#]:not(.tab-control, .modal-trigger)").click(function () {
    if (
      location.pathname.replace(/^\//, "") ==
        this.pathname.replace(/^\//, "") &&
      location.hostname == this.hostname
    ) {
      var $target = $(this.hash);
      if (this.hash.slice(1)) {
        $target =
          ($target.length && $target) || $("[name=" + this.hash.slice(1) + "]");
        if ($target.length) {
          var padding = 130;
          $("html, body").animate(
            {
              scrollTop: $target.offset().top - padding,
            },
            300
          );
          return false;
        }
      } else {
        $("html, body").animate(
          {
            scrollTop: 0,
          },
          300
        );
      }
    }
  });
};

esign.initAanbodTabsOnPageLoad = function () {
  if ($("body").hasClass("offer")) {
    if (
      location.hash.length &&
      $.inArray(location.hash, [
        "#opleidingen",
        "#workshops",
        "#lezingen",
        // "#e-learning",
      ]) >= 0
    ) {
      $('a[href="' + location.hash + '"].tab-control').trigger("click");
    } else {
      $('a[href="#opleidingen"].tab-control').trigger("click");
    }
    $("html, body").animate({ scrollTop: 0 }, 10);
  }
};

esign.initNewsletterForm = function () {
  var $gdpr = $("#newsletter-gdpr");
  var $form = $gdpr.closest("form");
  var $submitWrap = $form.find(".newsletter-form__submit");
  var $submitInput = $submitWrap.find("input");

  $gdpr.change(function () {
    if ($(this).is(":checked")) {
      $submitWrap.removeAttr("disabled");
      $submitInput.removeAttr("disabled");
    } else {
      $submitWrap.attr("disabled", "disabled");
      $submitInput.attr("disabled", "disabled");
    }
  });
};

esign.initForms = function () {
  $(".required-check-submit").each(function () {
    var $check = $(this);
    var $form = $check.closest("form");
    var $submit = $form.find(
      'input[type="submit"],.register-workshop__next[onclick]'
    );
    $check.change(function () {
      if ($(this).is(":checked")) {
        $submit.removeAttr("disabled");
      } else {
        $submit.attr("disabled", "disabled");
      }
    });
  });
};

esign.initRegisterForm = function () {
  if ($("body").hasClass("workshop-register")) {
    var $btnAddParticipant = $("#btn-add-participant");
    var $participantCardTemplate = $("#register__participant-card-template");
    var $participantControlList = $(".control-list--participant");
    var count = 0;

    var tabsMap = {
      "#gegevens-lesvolger": 1,
      "#gegevens-factuurklant": 2,
      "#controle-en-opmerking": 3,
      "#gegevens-betaling": 4,
    };

    $btnAddParticipant.click(function (e) {
      e.preventDefault();
      count++;
      var $newCard = $participantCardTemplate
        .clone()
        .attr("id", "card" + count)
        .removeClass("register__participant-card-template");
      var $newControlList = $participantControlList
        .clone()
        .attr("id", "card" + count + "-control-list");
      $(".control-list--participant")
        .last()
        .after($newControlList[0].outerHTML);
      $btnAddParticipant.parent().before($newCard[0].outerHTML);
      esign.initFloatingLabels("#card" + count + " input");
      $newCard = $("#card" + count);
      $newControlList = $("#card" + count + "-control-list");
      var listenToIds = [
        "#name",
        "#surname",
        "#email",
        "#profession",
        "#student",
      ];
      $.each(listenToIds, function (index, id) {
        var newId = id + count;
        var $dd = $newControlList.find('dd[data-listen-to="' + id + '"]');
        $dd.attr("data-listen-to", newId);
        var $input = $newCard.find(id);
        $input.attr("name", $input.attr("name").replace("{id}", count));
        $input.attr("id", newId);
        $input.parents(".form__item").find("label").attr("for", newId);

        $input.change(function () {
          $dd.html($input.val());
        });

        $input.trigger("change");
      });

      var $closeButton = $(".register__card-close-button");
      $closeButton.off("click");
      $closeButton.click(function () {
        var $closestCard = $(this).closest(".register__participant-card");
        $closestCard.addClass("register__participant-card--hidden");
        $("#" + $closestCard.attr("id") + "-control-list").remove();

        setTimeout(function () {
          $closestCard.remove();
        }, 600);
      });
      setTimeout(function () {
        $("#card" + count).removeClass("register__participant-card--hidden");
      });
      $(this).blur();
    });

    function fieldValid($field, validCallback, invalidCallback) {
      if ($field.hasClass("required") && $field.val() == "") {
        return invalidCallback($field);
      }

      if ($field.hasClass("required-email") && !emailValid($field.val())) {
        return invalidCallback($field);
      }

      if ($field.hasClass("valid-vat") && $field.val() != "") {
        return vatValid($field.val(), validCallback, function () {
          invalidCallback($field);
        });
      }

      return validCallback();
    }

    function emailValid(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return re.test(String(email).toLowerCase());
    }

    function vatValid(vat, validCallback, invalidCallback) {
      var url = vatChecker + "/" + vat;
      $.ajax({
        url: url,
        success: function (result) {
          if (result.is_valid === true) {
            return validCallback();
          }

          return invalidCallback();
        },
        error: function (result) {
          return invalidCallback();
        },
      });
    }

    function setTabActive(panelId) {
      setTabPanelActive(panelId);
      setTabControlActive(panelId);
      calculateAmount(panelId);
    }

    function setTabPanelActive(panelId) {
      var $activePanels = $(".tab-panel.active");
      $activePanels.each(function () {
        $(this).removeClass("active");
      });

      $("#" + panelId).addClass("active");
    }

    function calculateAmount(panelId) {
      const price = document.querySelector(".control-list--price");
      let priceNumber = parseFloat(price.innerHTML);
      const kmoCheckbox = document.getElementById("customer-use-kmo-wallet");
      const allStudentCheckbox = document.querySelectorAll(
        ".checkbox--student"
      );

      let totalStudentDiscount = 0;
      allStudentCheckbox.forEach((studentCheckbox) => {
        if (studentCheckbox.form != null) {
          let studentDiscount = 0;
          if (studentCheckbox.checked) {
            studentDiscount = priceNumber / 2;
          } else {
            studentDiscount = priceNumber;
          }
          totalStudentDiscount += studentDiscount;
        }
      });

      let totalPrice = totalStudentDiscount;
      let priceNumberKMO = (21 / 100) * totalStudentDiscount;

      if (kmoCheckbox.checked && totalStudentDiscount >= 100) {
        totalPrice = priceNumberKMO;
      } else {
        totalPrice = totalStudentDiscount + priceNumberKMO;
      }

      const totalPriceRounded =
        Math.round((totalPrice + Number.EPSILON) * 100) / 100;

      document.querySelector(
        ".control-list__amount-listener"
      ).innerHTML = `€${totalPriceRounded}`;
    }

    function setTabControlActive(panelId) {
      var $toggles = $(".tab-control");
      var isPrevious = true;
      $toggles.each(function () {
        var $parent = $(this).parents(".tab-list__tab").first();

        // $(this).removeClass("js-previous");
        if (isPrevious) {
          $(this).addClass("js-previous");
        }

        if ($(this).attr("href") == "#" + panelId) {
          isPrevious = false;
        }

        if ($parent.hasClass("active")) {
          $parent.removeClass("active");
        }
      });
      $('.tab-control[href="#' + panelId + '"]')
        .parents(".tab-list__tab")
        .first()
        .addClass("active");
    }

    $(".tab-control").click(function (e) {
      // No validation needed for previous clicks
      if ($(e.target).hasClass("js-previous")) {
        return setTabActive($(e.target).attr("href").substring(1));
      }

      // Stop new tab by default, will toggle tab manually upon validation
      e.preventDefault();
      e.stopImmediatePropagation();

      var $tabPanelBeforeSwitch = $(".tab-panel.active");
      var tabPanelBeforeSwitchId = $tabPanelBeforeSwitch.attr("id");
      var $inputsToCheck = $tabPanelBeforeSwitch.find("input.required");
      var $inputsToCheck = $.merge(
        $inputsToCheck,
        $tabPanelBeforeSwitch.find("input.valid-vat")
      );

      var validCount = 0;

      // Check if tab index is bigger then index of previous tab
      if (
        tabsMap[$(this).attr("href")] > tabsMap["#" + tabPanelBeforeSwitchId]
      ) {
        // Loop over all required input fields
        for (var i = 0; i < $inputsToCheck.length; i++) {
          var $input = $($inputsToCheck[i]);
          $input.removeClass("highlight");
          if ($input.is(":visible")) {
            // Actions via callbacks to make sure they are executed after any API calls needed
            fieldValid(
              $input,
              function () {
                validCount++;
                // If all required input fields are valid, then go to next tab
                if (validCount == $inputsToCheck.length) {
                  var $target = $(e.target).is(".tab-control")
                    ? $(e.target)
                    : $(e.target).parents(".tab-control").first();
                  setTabActive($target.attr("href").substring(1));
                  window.scrollTo(0, 0);
                }
              },
              function ($input) {
                $("#fill-in-form-modal-trigger").trigger("click");
                $input.addClass("highlight");
              }
            );
          }
        }
      }
    });
  }
};

esign.initListFilterTags = function () {
  var $tags = $(".list-filters__tags");
  if ($tags.length) {
    var $form = $tags.closest("form");
    var $submit = $form.find('button[type="submit"]');
    $tags.click(function (e) {
      setTimeout(function () {
        $submit.trigger("click");
      }, 100);
    });
  }
};

esign.testDiscountCode = function () {
  $(".js-check-discount_code").on("click", function (e) {
    e.preventDefault();
    var $target = $(e.currentTarget);
    var inputCode = $("#discount_code").val();
    var url = $target.data("url") + "?code=" + inputCode;

    $.get(url, function (data) {
      if (data.errors === false) {
        $(".js-check-discount-code__response__message--error").hide();
        $(".js-check-discount-code__response__message--success").show();
        $(".js-check-discount-code__response__message--success").text(
          data.message
        );
      } else {
        $(".js-check-discount-code__response__message--error").show();
        $(".js-check-discount-code__response__message--error").text(
          data.message
        );
        $(".js-check-discount-code__response__message--success").hide();
      }
    });
  });
};

// Initialize on docready
$(esign.init);
